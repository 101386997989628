.tab-bar {
    --duration: .7s;   

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    margin: 0;
    
    font-size: 1em;
    padding: .2em 1rem;
    align-items: center;
    justify-content: space-between;
    background-color: var(--nord0);
    z-index: 5;

    display: none;
}

@media screen and (max-width: 768px) {
    .tab-bar {
        display: flex;
    }
}

.menu-item {
    flex-grow: 1;
    z-index: 100;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    will-change: transform;
    padding: 1rem 0;
    transition: transform var(--timeOut , var(--duration));
}
.menu-item:hover {
    background-color: transparent;
}

.menu-item::before{
    content: "";
    z-index: -1;
    width: 4.2em;
    height: 4.2em;
    border-radius: 50%;
    position: absolute;
    transform: scale(0);
    transition: background-color var(--duration), transform var(--duration);
}

.menu-item.active {
    transform: translate3d(0, -.8em , 0);
}

.menu-item.active::before{
    transform: scale(1);
    background-color: var(--iconColor);
}

.icon{
    width: 2.6em;
    height: 2.6em;
    color: var(--nord4);
    stroke: var(--nord4);
    fill: transparent;
    stroke-width: 1pt;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 400;
}

.menu-item.active .icon {
    animation: strok 1.5s reverse;
}

@keyframes strok {
    100% {
        stroke-dashoffset: 400;
    }
}

.menu-border{
    left: 0;
    bottom: 99%;
    width: 10.9em;
    height: 2.4em;
    position: absolute;
    clip-path: url(#menu);
    will-change: transform;
    background-color: var(--nord0);
    transition: transform var(--duration), height 0.5s;
}

.svg-container {
    width: 0;
    height: 0;
}

.menu-border.transition{
    height: 0;
}