.App {
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
}
main * {
    margin: 0;
    padding: 0;
    color: var(--nord4);
}
main {
    min-height: calc(100vh - max(8vh, 80px));
    max-height: calc(100vh - max(8vh, 80px));
    background-color: var(--nord0);
}
