#reddit {
    background-color: var(--nord2);
    padding-top: 1rem;
    padding-bottom: 200px;
    max-height: unset;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

#reddit div.content {
    display: flex;
    flex-flow: row nowrap;
    gap: 1em;
}

#reddit h1 {
    font-size: 1.5rem;
    letter-spacing: .5rem;
}
#reddit h1 span {
    letter-spacing:normal;
}

@media screen and (max-width: 768px) {
    #reddit div.content{
        flex-direction: column-reverse;
    }
    #reddit h1 {
        margin: 0 auto;
    }
}

#reddit p {
    padding: 2rem;
    border: 1px solid black;
    text-align: justify;
    line-height: 1.25rem;
}

#reddit * {
    list-style: none;
}

/* #reddit div.content > * {
    border: 1px solid red;
} */

#reddit .header {
    display:  flex;
    flex-flow: column nowrap;
    gap: 1em;
}

#reddit div.content {
    display: flex;
    gap: 1em;
    width: min(1200px, 90%);
}


#reddit div.content .left {
    flex: 2;
}

#reddit div.content .right {
    flex: 1;
}

#reddit .subreddit-buttons {
    display: flex;
    gap: .5em;
}

#reddit .subreddit-buttons > * {
    flex: 1;
    background-color: var(--nord1);
    padding: 1rem 0;
    border-radius: 4px;
    outline: 0;
    border: 0;
    
}

#reddit .subreddit-buttons > *:hover {
    background-color: var(--nord3);
}

#reddit .subreddit-buttons > *.selected {
    transform: translateY(-10%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
    transition: transform cubic-bezier(0.18, 0.87, 0.69, 2.12) .1s;
    background-color: var(--nord3);
}