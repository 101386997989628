#home {
    margin-bottom: 260px;
}

section {
    padding: 0;
    margin: 0;
    min-height: 50vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: right;
}
.section-content{
    width: min(800px, 90vw);
    margin: 0 auto;
}

h1 {
    padding: 2rem 0;
    font-weight: 300;
    font-size: 3rem;
    text-align: left;
}
h2 {
    padding: 2rem 0;
    font-weight: 300;
    font-size: 3rem;
    text-align: right;
}

.spacer {
    aspect-ratio: 1200/300;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.layer1 {
    background-image: url('./transition.svg');
}

#highlights {
    background-color: var(--nord2);
    text-align: left;
}