nav * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: var(--nord4);
}

nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: max(8vh, 80px);
    background-color: var(--nord2);
    z-index: 5;
}
.nav-links {
    padding: 0 2rem;
    row-gap: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    height: 100%;
}
.nav-links li {
    width: 100px;
    border-radius: 8px;
    height: 100%;
}
.nav-links li:hover {
    transition-property: background-color;
    transition-duration: .25s;
    background-color: var(--nord10);
}
.nav-links a {
    color: inherit;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
}
.burger {
    display: none;
    /* cursor: pointer; */
}
.burger > * {
    background-color: var(--nord4);
    width: 28px;
    height: 3px;
    margin: 5px;
    border-radius: 2px;
    transition: all 0.3s ease;
}

nav input {
    right: 0;
    width: 100px;
    display: none;
    height: 20px;
    text-align: center;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
}

@media screen and (max-width: 768px) {
    nav {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        justify-content: space-between;
        padding: 0 2rem;
        
    }

    body {
        overflow-x: hidden;
    }

    .nav-links {
        position: absolute;
        right: 0;
        background-color: var(--nord2);
        top: max(8vh, 80px);
        width: min(300px, 50%);
        height: calc(100vh - max(8vh, 80px));
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        transform: translateX(100%);
        transition-property: transform opacity;
        transition-duration: 0.5s;
        opacity: 0;
    }
    .nav-links li {
        opacity: 0;
        width: 100%;
        height: max(5vh, 6rem);
    }

    .burger {
        display: block;
    }

    nav input {
        display: block;
    }
}

.nav-active {
    transform: translateX(0);
    opacity: 1;
}

input:checked ~ .burger .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
}

input:checked ~ .burger .line2 {
    opacity: 0;
}

input:checked ~ .burger .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
}

input:checked ~ .nav-links {
    transform: translateX(0);
    opacity: 1;
}

@keyframes navLinkFade {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}