html {
    --nord0: #2E3440;
    --nord1: #3B4252;
    --nord2: #434C5E;
    --nord3: #4C566A;

    --nord4: #D8DEE9;
    --nord5: #E5E9F0;
    --nord6: #ECEFF4;
    
    --nord7: #8FBCBB;
    --nord8: #88C0D0;
    --nord9: #81A1C1;
    --nord10: #5E81AC;
    
    --nord11: #BF616A;
    --nord12: #D08770;
    --nord13: #EBCB8B;
    --nord14: #A3BE8C;
    --nord15: #B48EAD;
    box-sizing: border-box;
}
html *,
html *::before,
html *::after {
    box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media screen and (max-width: 768px) {
    main {
        margin-top: max(8vh, 80px);
    }
}
