.post {
    margin-bottom: 1rem;
    border-radius: 4px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.25);
    background-color: var(--nord1);
}

.post a {
    text-decoration: none;
    padding: 1em;
    display: flex;
    flex-flow: column nowrap;
}

.post p {
    border: transparent !important;
    margin: 0;
    padding: 0 !important;
}

.post .author {
    font-size: 0.8rem;
    color: var(--nord8);
}

.post .preview {
    margin-top: 1em;
    width: 100%;
    height: auto;
}

.post .comments {
    display:flex;
    justify-content: flex-end;
    align-items: center;
    gap:.25em;
    margin-top:1em;
    font-size: smaller;
}